<i18n>
ru:
  buttonDefaultText: Стать партнером
  chipsTitle: Партнёрство
  description: >-
    Полезные знакомства – это всегда хорошо, и мы им очень рады. Мы ценим
    каждого своего партнера, чтобы наша работа была продуктивной и выгодной как
    для нас, так и для других. Мы готовы рассмотреть предложения в области
    маркетинга и рекламы, вопросы по предоставлению франшизы и кейтеринга. Так
    же нас интересуют потенциальные поставщики продукции и других различных
    услуг. Если вам интересно наше предложение, оставляйте заявку на сайте или
    звоните нам.
  partners: 'Наши партнеры:'
  title: Приглашаем к партнерству
ua:
  buttonDefaultText: Стати партнером
  chipsTitle: Партнерство
  description: >-
    Корисні знайомства – це завжди добре, і ми їм дуже раді. Ми цінуємо кожного
    свого партнера, щоб наша робота була продуктивною та вигідною як для нас,
    так і для інших. Ми готові розглянути пропозиції в галузі маркетингу та
    реклами, питання щодо надання франшизи та кейтерингу. Також нас цікавлять
    потенційні постачальники продукції та інших різних послуг. Якщо вас цікавить
    наша пропозиція, залишайте заявку на сайті або телефонуйте нам.
  partners: 'Наші партнери:'
  title: Запрошуємо до партнерства
us:
  buttonDefaultText: Become a partner
  chipsTitle: Partnership
  description: >-
    Valuable connections are always a good thing, and we are very happy about
    them. We value each of our partners so that our work is productive and
    profitable both for us and for others. We are ready to consider proposals in
    the field of marketing and advertising, issues related to franchising and
    catering. We are also interested in potential suppliers of products and
    other various services. If our offer spiked your interest, please leave a
    request on the website or call us.
  partners: 'Our Partners:'
  title: We invite you to our partnership
</i18n>

<template>
  <section class="v-partnership">
    <template
      v-if="
        imageExists(appConfig.VueSettingsPreRun.PartnershipBannerDesktop) ||
        imageExists(appConfig.VueSettingsPreRun.PartnershipBannerMobile) ||
        imageExists(appConfig.VueSettingsPreRun.PartnershipBannerTablet)
      "
    >
      <div
        v-if="isDesktopVersion"
        :class="[
          imageExists(appConfig.VueSettingsPreRun.PartnershipBannerDesktop)
            ? 'v-partnership--intro-banner v-mb-md'
            : 'v-partnership--intro-banner v-partnership--intro-banner__placeholder v-mb-md'
        ]"
      >
        <arora-nuxt-image
          v-if="imageExists(appConfig.VueSettingsPreRun.PartnershipBannerDesktop)"
          :alt="translate('partnershipPage.buttonDefaultText')"
          :image="appConfig.VueSettingsPreRun.PartnershipBannerDesktop"
        />
        <icon-general-placeholder-img v-else />
      </div>
      <div
        v-else-if="isExtraSmall"
        :class="[
          imageExists(appConfig.VueSettingsPreRun.PartnershipBannerMobile)
            ? 'v-partnership--intro-banner v-mb-md'
            : 'v-partnership--intro-banner v-partnership--intro-banner__placeholder v-mb-md'
        ]"
      >
        <arora-nuxt-image
          v-if="imageExists(appConfig.VueSettingsPreRun.PartnershipBannerMobile)"
          :alt="translate('partnershipPage.buttonDefaultText')"
          :image="appConfig.VueSettingsPreRun.PartnershipBannerMobile"
        />
        <icon-general-placeholder-img v-else />
      </div>
      <div
        v-else
        :class="[
          imageExists(appConfig.VueSettingsPreRun.PartnershipBannerTablet)
            ? 'v-partnership--intro-banner v-mb-md'
            : 'v-partnership--intro-banner v-partnership--intro-banner__placeholder v-mb-md'
        ]"
      >
        <arora-nuxt-image
          v-if="imageExists(appConfig.VueSettingsPreRun.PartnershipBannerTablet)"
          :alt="translate('partnershipPage.buttonDefaultText')"
          :image="appConfig.VueSettingsPreRun.PartnershipBannerTablet"
        />
        <icon-general-placeholder-img v-else />
      </div>
    </template>
    <div
      class="v-partnership--title v-mb-sm v-title"
      v-html="translate('partnershipPage.title')"
    />
    <div
      class="v-partnership--description v-mb-lg"
      v-html="translate('partnershipPage.description')"
    />
    <div class="v-mb-xxl">
      <common-cards-flex-mesh
        class-name="v-partners-card"
        :items="componentBlockPartnership"
        :max-items="2"
      >
        <template #item="item: ComponentBlock">
          <div class="v-partnership-single v-d-flex v-flex-column">
            <div class="v-partnership-single--title-and-subtitle">
              <div
                v-if="
                  componentBlockPartnership.some((block: ComponentBlock) => imageExists(block.Image))
                "
                class="v-d-flex v-justify-content-between"
              >
                <div
                  :class="[
                    imageExists(item.Image)
                      ? 'v-partnership-single--image'
                      : 'v-partnership-single--image v-partnership-single--image__placeholder'
                  ]"
                >
                  <arora-nuxt-image
                    v-if="imageExists(item.Image)"
                    :alt="item.Title"
                    :image="item.Image"
                  />
                  <icon-general-placeholder-img v-else />
                </div>
                <div class="v-partnership-single--image-text">
                  <div
                    class="v-partnership-single--title v-partnership-single--title__has-image v-mb-xs"
                    v-html="item.Title"
                  />
                  <div
                    class="v-partnership-single--subtitle v-mb-xs"
                    v-html="item.Subtitle"
                  />
                </div>
              </div>
              <div v-else>
                <div
                  class="v-partnership-single--title v-mb-xs"
                  v-html="item.Title"
                />
                <div
                  class="v-partnership-single--subtitle v-mb-xs"
                  v-html="item.Subtitle"
                />
              </div>
            </div>
            <div class="v-partnership-single--hr">
              <hr />
            </div>
            <div class="v-partnership-single--info">
              <div
                class="v-partnership-single--text v-mb-xs"
                v-html="item.Text"
              />
              <div class="v-d-flex v-flex-row v-justify-content-end v-partnership-single--button">
                <arora-button
                  :label="
                    stringIsNullOrWhitespace(sanitize(item.ButtonText))
                      ? translate('partnershipPage.buttonDefaultText')
                      : sanitize(item.ButtonText)
                  "
                  @click="() => buttonClick(item)"
                />
              </div>
            </div>
          </div>
        </template>
      </common-cards-flex-mesh>
    </div>
    <div
      v-if="externalLinksPartners.length > 0"
      class="v-partnership-slider v-overflow-hidden v-mb-xxl"
    >
      <div
        class="v-partnership-slider--title"
        v-html="translate('partnershipPage.partners')"
      />
      <arora-swiper-slider
        class-name="v-w-100"
        show-part-of-next-slide
        :autoplay-delay="externalLinksPartners.length > 2 ? 3000 : 0"
        :items="externalLinksPartners"
        :loop="externalLinksPartners.length > 2"
        :max-items="5"
        :min-items="2"
        :space-between="40"
        pagination-type="none"
      >
        <template #item="item: ExternalLink">
          <div class="v-h-100">
            <arora-nuxt-image
              :alt="translate('partnershipPage.buttonDefaultText')"
              :image="item.Image"
            />
          </div>
        </template>
      </arora-swiper-slider>
    </div>
    <page-partnership-form
      v-if="
        componentBlockPartnership.some((item: ComponentBlock) =>
          stringIsNullOrWhitespace(item.ButtonLink)
        )
      "
    />
  </section>
</template>

<script setup lang="ts">
import type { ComponentBlock, ExternalLink } from '~types/settingsVariants'

import { useCommon, useWindowSize } from '@arora/common'

const pageStore = usePageStore()

const appConfig = useAppConfig()
const { stringIsNullOrWhitespace } = useCommon()
const { translate, sanitize } = useI18nSanitized()
const { imageExists } = useImageInfo()

const { isDesktopVersion, isExtraSmall } = useWindowSize()

function buttonClick(block: ComponentBlock): void {
  if (stringIsNullOrWhitespace(block.ButtonLink)) {
    pageStore.SelectedPartnershipId = block.ID

    const element = document.getElementById('v-partners-form')

    if (element) element.scrollIntoView({ block: 'center', behavior: 'smooth' })
  } else {
    navigateTo(block.ButtonLink, {
      external: true
    })
  }
}

const externalLinksPartners = ref<ExternalLink[]>(
  appConfig.VueSettingsPreRun.ExternalLinks.filter((l) => l.Type === 'partners').sort(
    (a, b) => a.SortWeight - b.SortWeight
  )
)

const componentBlockPartnership = ref<ComponentBlock[]>(
  appConfig.VueSettingsPreRun.ComponentBlocks.filter(
    (l) => l.Type === 'Partnership' && (l.Title ?? '').trim().length > 0
  ).sort((a, b) => a.SortWeight - b.SortWeight)
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-partnership {
  &--title {
    font-size: variables.$TextSizeH2;
    font-weight: 600;
  }

  &--description {
    font-size: 1.1rem;
  }

  &--intro-banner {
    &__placeholder {
      background: variables.$BodyBackgroundDarken;
      color: variables.$BodyTextColorLight;

      border-radius: 4px;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 50px 0;

      svg {
        width: 170px;
        height: 170px;
      }
    }

    .v-img-fluid {
      border-radius: variables.$BorderRadius;
      width: 100%;
    }
  }

  .v-partnership-single {
    padding: 20px;
    height: 100%;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content 2.5rem auto;
    gap: 0;
    align-items: stretch;

    grid-template-areas:
      'titleAndSubtitle'
      'hr'
      'info';

    &--hr {
      grid-area: hr;
      display: flex;
      flex-direction: column;
      justify-content: center;

      hr {
        width: 100%;
        color: variables.$BodyTextColor;
        opacity: 0.25;

        //TODO: [after SSR]: remove those styles
        margin: 0;
        margin-block-start: 0.5em;
        margin-block-end: 0.5em;
        margin-inline-start: auto;
        margin-inline-end: auto;
        border-top-style: inset;
        border-top-width: 1px;
      }
    }

    &--title-and-subtitle {
      grid-area: titleAndSubtitle;
    }
    &--info {
      grid-area: info;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &--title {
      color: variables.$PrimaryBackgroundColor;
      font-size: variables.$TextSizeH3;
      font-weight: 600;
    }

    &--image-text {
      flex: 0 0 calc(100% - 60px);
      max-width: calc(100% - 60px);
    }

    &--image {
      flex: 0 0 50px;
      max-width: 50px;

      &__placeholder {
        background: variables.$BodyBackgroundDarken;
        color: variables.$BodyTextColorLight;

        border-radius: 4px;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .v-img-fluid {
        width: 40px;
        height: 40px;
      }

      svg {
        width: 40px;
        height: 40px;
      }
    }

    &--button {
      .v-btn {
        width: fit-content;
      }
    }

    &--subtitle {
      color: variables.$BodyTextColorLight;
      font-size: variables.$TextSizeMain;
    }

    &--text {
      ul {
        list-style: disc;
        padding-left: 2.5rem;
        padding-top: 0.5rem;
        margin-bottom: 0.5rem;
      }

      ol {
        list-style: disc;
        padding-left: 2.5rem;
        padding-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .v-partnership-slider {
    display: flex;
    align-items: center;

    @include mixins.sm {
      flex-direction: column;
      align-items: flex-start;
    }

    .swiper-wrapper {
      align-items: center;
    }

    &--title {
      text-wrap: nowrap;
      font-size: 1.45rem;
      font-weight: 600;
      margin-right: 40px;

      @include mixins.sm {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }

  .v-partners-card {
    min-height: 100%;
  }
}
</style>
